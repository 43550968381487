.m-caution_dialog{
  position: absolute;
  z-index: 999;
  left: 0;
  top:0;
  padding: 10px 0;
  display: block;
  background: #C62A2A;
  color: $white;
  width: 100%;
  @include tablet{
    font-size: 1.2rem;
  }
  .caution_txt{
    position: relative;
    padding-left: 35px;
    &:before{
      position: absolute;
      content: '';
      left: 0;
      @include trans-y();
      width: 25px;
      height: 25px;
      background: url(#{$dir}/common/ico-caution.svg) no-repeat center center;
      background-size: contain;

    }
  }
}