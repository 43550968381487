.l-welcome{
	padding-bottom: 100px;
	@extend %form-reset;
	@extend %form;
	input[type='text']{
		padding: 10px 20px;
		border-radius: 4px;
		font-weight: 300;
	}
	.c-title-big{
		font-weight: 600;
	}
	.c-lead{
		opacity: 0.6;
		margin-bottom: 40px;
	}
	.h1-img{
		margin: 0 auto;
		width: 350px;
		img{
			width: 100%;
		}
		@include tablet{
			width: 250px;
		}

	}
	.copy-input{
		display: block;
		max-width: 300px;
		position: relative;
		cursor: pointer;
		input{

		}
		&:after{
			@include content(20px,20px);
			@include trans-y();
			right: 10px;
			background: url(/assets/img/common/ico-copy.png) center center no-repeat;
			background-size: contain;

		}
	}
	.add-domain{
		text-align: right;
		color: $color00;
		.add-btn{
			cursor: pointer;
			margin-bottom: 40px;
			display: inline-block;
		}
		.add-btn:before{
			content:'+';
			font-weight: 300;
			margin-right: 10px;
		}
		.add-domain_wrap{
			margin: 0 0 40px;
			padding: 10px 40px 20px;
			background: $base-gray;
			border-radius: 5px;
			.c-btn_accept{
				margin: 10px auto 0;
			}
			.input-mole-list{
				//max-width: 100px;
				//display: inline-block;
				input{
					display: inline-block;
					max-width: 200px;
					background: $white;
					margin:0 10px;
					border: $gray solid 1px;
				}
			}
			@include tablet{
				padding: 10px 20px 20px;
			}
		}
	}
	.c-btn_app{
		margin: 40px auto 0;
	}
	.get-mole-wrap{
		&.is-none{
			display: none;
		}
	}
	.get-mole{
		padding:80px 0;
		p.txt{
			font-size: 220%;
			text-align: center;
			color: #888;
		}
	}

	.view-mole{
		padding: 10px 40px;
		box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
		box-sizing: border-box;
		border-radius: 5px;
		border: 1px solid #888;
		margin-bottom: 40px;
		position: relative;
		@include tablet{
			padding: 10px 20px;
		}
		&.is-none{
			display: none;
		}
		.mole-close{
			display: block;
			cursor: pointer;
			border-radius: 50%;
			width: 35px;
			height: 35px;
			background: $color00;
			position: absolute;
			top: -15px;
			right: -15px;
			box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
			&:before,&:after{
				@include content(65%,2px,$white);
			}
			&:before{
				@include trans-xy(45)
			}
			&:after{
				@include trans-xy(-45)
			}
		}

	}

	.input-mole-list{
		@include flex;
		justify-content: space-between;
		text-align: left;
		align-items: center;
		color: $black;
		dt,dd{
			padding: 15px 0;
		}
		dt{
			width: 160px;
		}
		dd{

			font-weight: 300;
			width:calc(100% - 160px);
		}
		.contentsName{
			color: #888;
		}
		.moleName{
			color: $color00;
			font-weight: 500;
			margin-left: 10px;
		}
		@include tablet{
			dt,dd{
				width: 100%;
			}

		}
	}
	.c-btn-lists{
		margin-top: 60px;
		@include flex;
		justify-content: center;
		.c-btn_submit{
			margin: 0;

			max-width: inherit;
			width: 320px;
		}
		.c-btn_app{
			margin: 0;
			margin-right: 20px;
			width: 320px;
			max-width: inherit;
		}
		@include tablet{
			.c-btn_app{
				margin: 0 auto 20px;
			}
		}
	}
	#formState .c-message{
		margin-bottom: 0;
	}
	.c-btn_accept{
		margin: 0 auto;
	}
	.checker{
		text-align: center;
		margin: 30px auto;
		a{
			text-decoration: underline;
			color: #2b9cf6;
		}
	}
	.c-btn_accept #acceptBtn.is-active{
		opacity: .6;
		pointer-events: none;
		&.is-checked{
			opacity: 1;
			pointer-events: auto;
		}
	}
}