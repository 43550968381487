.l-release{
  @extend .l-contact;
  .title-wrap{
    margin-bottom: 60px;
  }

  .c-release_box{
    margin-bottom: 60px;
    .release_title{
      margin-bottom: 5px;
      font-size: 160%;
    }
    .date{
      font-size: 90%;
      margin-bottom: 20px;
    }
    .title{
      margin-bottom: 20px;
    }
    .txt{
      font-weight: 400;
      margin-bottom: 20px;
      line-height: 1.8;
      color: #666;
    }
    ul{
      line-height: 1.8;
      font-weight: 400;
      margin: 20px 0 15px;
      li{
        color: #666;
        position: relative;
        padding-left: 1em;
        margin-bottom: 5px;
        &:before{
          content: '・';
          position: absolute;
          left: 0;
        }
      }
    }
  }
}


