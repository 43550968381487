.l-plan{
  padding: 0 0 60px;
  .title-wrap{
    max-width: 800px;
    margin: 0 auto 80px;
    em{
      font-size: 1.2em;
      margin-right: .2em;
    }
    @include tablet{
      margin-bottom: 20px;
    }
  }

  .plan-box{
    @include flex;
    font-size: 1.2rem;
    justify-content: space-around;


    .plan-item{
      width: 25%;
      box-sizing: border-box;
      padding:40px 20px;
      &.cbt{
        opacity: .4;
        pointer-events: none;
      }
      &:nth-child(odd){
        background: $color01;
      }
      &:nth-child(even){
        background: $color02;
      }

      &.is-trial{
        border: 5px solid $color08;
        padding: 35px 15px;
        position: relative;
      }
      .beta-txt{
        margin-top: 20px;
      }
      .trial-title{
        position: absolute;
        @include trans-x();
        display: inline-block;
        color: #bf0000;
        font-weight: bold;
        text-align: center;
        padding: 5px 15px;
        font-size: 1.4rem;
        background: $color08;
        border-radius: 40px;
        width: 160px;
        top:-20px;
      }
      @include tablet{
        width: 100%;
        padding:25px 20px;
        &.is-trial{
          border: 5px solid $color08;
          padding: 25px 15px;
          position: relative;
        }
        .trial-title{
          font-size: 1.2rem;
          top: -18px;
        }
      }
    }
    .txt{
      font-size: 1.2rem;
      margin: 10px auto 20px;
    }
    h3{
      font-size: 2.4rem;
    }
    .price{
      text-align: center;
      font-size: 1.6rem;
      font-weight: 900;
      margin-bottom: 5px;
      em{
        font-size: 2em;
      }
      .beta-test-txt{
        margin: 0 auto;
        max-width: 170px;
        color: #bf0000;
        display: block;
        text-align: left;
        font-size: 1.4rem;
        margin-bottom: 13px;
      }
    }
    .c-btn_small{
      margin-bottom: 20px;
    }
    .lead{
      font-size: 1.4rem;
      font-weight: 700;
      margin-bottom: 15px;
    }
    .dot-list{
      li{
        position: relative;
        padding-left: 1.2em;
        margin-bottom: 15px;
        &:last-child{
          margin-bottom: 0;
        }
        &:before{
          @include content(5px,5px,$color00);
          border-radius: 50%;
          left: 0;
          top:7px;
        }
      }
    }
    @include tablet{
      max-width: 550px;
      margin: 0 auto;
      h3{
        font-size: 3rem;
      }
      .txt{
        font-size: 1.6rem;
      }
      .price-wrap{
        @include flex;
        justify-content: space-between;

        .c-btn_small{
          width: 150px;
        }
      }
      .dot-list{
        li{
          margin-bottom: 10px;
          padding-left: 1.2em;
        }
      }
    }
  }
}


.l-service{
  .caution{
    font-weight: 300;
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .service-box{
    margin-top: 40px;
    img{
      width: 100%;
    }
  }
}