.c-wave{
	position: relative;
	%wave{
		width: 100%;
		display: block;
		position: absolute;
		z-index: 40;
		@include trans-x;
		img{
			width: 100%;
			height: 80px;
		}
		@include tablet{
			width: 180%;
		}
	}
	.wave-top{
		@extend %wave;
		top:0;
		@include tablet{
			top: -5px;
		}
	}
	.wave-bottom{
		@extend %wave;
		bottom:-5px;
		@include tablet{
			bottom: -10px;
		}
	}
}