.l-qa{

  .title-wrap{
    max-width: 500px;
    margin: 0 auto 40px;
    .c-title-en{
    }
    em{
      font-size: 1.2em;
      margin-right: .2em;
    }
    @include tablet{
      margin-bottom: 20px;
    }
  }
  .qa-tag{
    padding-bottom: 60px;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    @include flex;
    justify-content: space-between;
    li{
      a{
        height: 100%;
        text-align: center;
        @include flex;
        align-items: center;
        position: relative;
        padding-bottom: 12px;
        &:after{
          @include content(5px,5px);
          border-bottom: 2px solid $black;
          border-right: 2px solid $black;
          @include trans-x(45);
          bottom:0;
        }
      }
    }
    @include tablet{
      margin-top: 40px;
      text-align: center;
      border-bottom: 1px solid $gray;

      padding: 0;
      margin-bottom: 60px;
      li{
        width: 33.3%;
        border-top: 1px solid $gray;
        border-right: 1px solid $gray;
        box-sizing: border-box;
        &:nth-child(3),&:nth-child(6){
          border-right: none;
        }

        a{
          box-sizing: border-box;
          width: 100%;
          justify-content: center;
          line-height: 1.4;
          font-size: 1.2rem;
          padding: 15px 0 30px;
          &:after{
            bottom:15px;
          }
        }
      }
    }
  }

  .qa-wrap{
    margin-bottom: 60px;
    .c-title-mid{
      margin-bottom: 20px;
    }
    .qa-list{
      //border-bottom: 1px solid $black;
      line-height: 1.8;
      dt{
        padding: 20px 20px 20px 45px;
        position: relative;
        cursor: pointer;
        &:before{
          @include content(30px,30px);
          background: url(#{$dir}/qa/ico-question.svg) no-repeat center center;
          background-size: contain;
          top:18px;
          left: 0;
        }
      }
      dd{
        position: relative;
        font-weight: 400;
        margin-top: 10px;
        padding: 20px 20px 20px 70px;
        display: none;
        background: #F4F4F4;
        font-size: 1.4rem;
        &:after{
          @include content(10px,10px);
          @include arrow(top,20,#F4F4F4);
          bottom:100%;
          left: 25px;
        }
        &:before{
          @include content(30px,30px);
          background: url(#{$dir}/qa/ico-answer.svg) no-repeat center center;
          background-size: contain;
          top:18px;
          left: 20px;
        }
      }
      .is-active + dd{
        display: block;
      }
      .c-dot-list{
        margin: 10px 0;
        li{
          padding-left: 1.2em;
          position: relative;
          &:before{
            content: '・';
            position: absolute;
            left: 0;
          }
        }
      }
      @include tablet{
        dt {
          padding: 10px 40px 10px 10px;
          .toggle {
            position: absolute;
            right: 10px;
            top: 15px;
            width: 13px;
            height: 13px;
          }
        }
        dd{
          padding: 5px 40px 10px 10px;
        }
      }
    }
  }
}