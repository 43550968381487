.c-title-en{
  @include web-font02;
  font-size: 3.1rem;
  color: $color00;
  margin-bottom: 30px;
  letter-spacing: 0;
  span{
    position: relative;
    padding-left: 43px;
    display: inline-block;
    &:before{
      @include content(36px,2px,$color00);
      @include trans-y;
      left: 0;
    }
  }
  &.is-right{
    text-align: right;
  }

  @include tablet{
    font-size: 1.4rem;
    margin-bottom: 30px;
    span{
      padding-left: 30px;
      &:before{
        @include content(25px,1px,$color00);
      }
    }
  }

}

.c-title-small{
  font-weight: 700;
  font-size: 1.8rem;
  small{
    font-size: .65em;
  }
  @include tablet{
    font-size: 1.4rem;
  }
}

.c-title-mid{
  font-weight: 700;
  text-align: center;
  font-size: 2.6rem;
  @include tablet{
    font-size: 1.8rem;
  }
}

.c-title-big{
  font-weight: 900;
  text-align: center;
  font-size: 3.8rem;
  margin-bottom: 20px;
  @include tablet{
    font-size: 2.1rem;
  }
}


.c-mole-logo{
  letter-spacing: 0;
  font-weight: 300;
  em{
    font-weight: 900;
  }
}