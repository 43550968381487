.l-signout{
  @extend .l-contact;
  padding-top: 20px;

  .no-account{
    text-align: center;
    display: block;
    margin: 20px auto 40px;
    width: 100%;
  }
  .c-title-mid{
    margin-bottom: 20px;
  }
  .c-dotted{
    max-width: 500px;
    font-size: 1.4rem;
    margin: 0 auto;
    li{
      margin-bottom: 5px;
      position: relative;
      padding-left: 1em;
      font-size: 1.4rem;
      color: #666;
      &:before{
        content: '・';
        position: absolute;
        left: 0;
      }
    }
  }
  .h1-img{
    margin: 0 auto;
    max-width: 280px;
    img{
      display: block;
      width: 100%;
    }
  }
  .c-btn-lists{
    @include flex;
    justify-content: center;
    margin-top: 40px;
    li{
      margin: 0 15px;
      width: 140px;
      text-align: center;
      background: #eee;
      color: $white;
      transition:opacity .3s;
      cursor: pointer;
      padding: 7px;
      border: #ddd solid 1px;
      &:hover{
        opacity: .6;
      }
      &.yes{
        background: #E28181;
      }
      &.no{
        background: #81B5E2;
      }
    }
  }
}


