// MainVisual
.c-main_visual{
	padding: 280px 0 0;
	background: $bg-color01 url(/assets/img/top/bg01.jpg) top center no-repeat;
	height: 900px;
	overflow: hidden;
	box-sizing: border-box;
	.c-mole_box{
		top: 120px;
		@include tablet{
			top:310px;
		}
	}
	.inner-wide{
		height: 100%;
	}
	.mv-svg{
		position: absolute;
		z-index: 0;
		@include trans-x();
		width: 500px;
		top: -50px;
		left: calc(50% + 240px);
		img{
			width: 100%;
			position: absolute;
			top:0;
			left: 0;
		}
		@include tablet{
			width: 220px;
			top: 230px;
			left: calc(50% + 110px);
		}
	}
	.main_txt{
		font-size: 6.0rem;
		font-weight: 900;
		margin-bottom: 60px;
		@include siteSize{
			font-size: 5.5rem;
		}
		@include tablet{
			font-size: 3rem;
			margin-bottom: 20px;
		}
	}

	@include tablet{
		background-image: url(/assets/img/top/bg01-sp.jpg);
		background-position: center top;
		background-size: 780px;
		padding: 110px 0 0;
		height: 680px;
		//padding: 0;
	}
}
.top-kv1,.top-kv2,.top-kv3{
	position: relative;
	height: 900px;
	.inner-wide,.inner{
		height: 100%;
		overflow: hidden;
	}
}


.top-kv1{
	background: $bg-color02 url(/assets/img/top/bg02.jpg) top center no-repeat;
	.kv1-svg{
		position: absolute;
		@include trans-x();
		top: 200px;
		left: 50%;
		z-index: 0;
		width: 800px;
		img{
			width: 100%;
		}
		@include tablet{
			width: 350px;
			left: calc(50% + 10px);
			top: 150px;
		}
	}
	.c-mole_box{
		top: 260px;
		&:after{
			@include content(80px,80px);
			background: url(/assets/img/common/line.svg) no-repeat center center;
			background-size: contain;
			opacity: 0;
			left: 110px;
			top: 116px;
			transition: opacity .3s;
			@include tablet{
				width: 40px;
				height: 40px;
				left: 59px;
				top: 62px;
			}
		}
		&.is-waiting{
			&:after{
				//opacity: 1;

			}
		}
		&.is-fallDown:after{
			opacity: 0;
		}
		@include tablet{
			top:170px;
		}
	}

	.message{
		position: relative;
		z-index: 80;
		.inner{
			height: 0;
			overflow: inherit;
		}
		.message01,.message02,.message03{
			position: absolute;
			@include tablet{
				width: 150px;
				img{
					width: 100%;
				}
			}
		}
		.message01{
			top:-30px;
			left: 130px;
		}
		.message02{
			top:-35px;
			right: -10px;
		}
		.message03{
			top:150px;
			right: 150px;
		}
		@include tablet{
			.message01{
				top:-10px;
				@include trans-x();
				left: calc(50% - 120px);
			}
			.message02{
				top:-35px;
				left: calc(50% + 53px);
			}
			.message03{
				top:90px;
				right: inherit;
				left: 55%;
			}
		}

	}
}
.top-kv2{
	background: $bg-color01 url(/assets/img/top/bg01.jpg) top center no-repeat;
	height: 850px;
	@include tablet{
		height: 500px;
	}
	.kv2-svg{
		position: absolute;
		@include trans-x();
		top: 110px;
		left: calc(50% - 60px);
		z-index: 0;
		width: 820px;
		img{
			width: 100%;
		}
		@include tablet{
			width: 400px;

			left: calc(50% - 30px);
			top: 100px;
		}
	}
}
.top-kv3{
	background: $bg-color02 url(/assets/img/top/bg02.jpg) top center no-repeat;
	.kv3-svg{
		width: 1000px;
		position: absolute;
		@include trans-x();
		top:120px;
		img{
			width: 100%;
		}
		@include tablet{
			top:120px;
			width: 430px;
		}
	}
}

.top-kv1,.top-kv2,.top-kv3{
	@include tablet{
		height: 500px;
		background-size: 800px;
	}
}


.c-keyVisual{
	height: 230px;
	background: $bg-color01 url(/assets/img/top/bg01.jpg) center center no-repeat;
	background-position-Y: 80%;
	margin-bottom: 60px;
	.c-mole_box{
		z-index: 50;
		top:130px;
	}
	@include tablet{
		height: 150px;
		background-position-Y: 68%;
		margin-bottom: 40px;
		.c-mole_box{
			top:90px;
		}
	}
	&.c-console{
		.c-mole_box{
			display: none;
		}
		&.myPage{
			.c-mole_box {
				display: block;
			}
		}
	}
}


.kv_footer{
	background: url(/assets/img/top/bg01.jpg) top center no-repeat;
	height: 770px;
	box-sizing: border-box;
	overflow: hidden;
	.inner{
		height: 100%;
		position: relative;
	}
	.hole-wrap {
		position: absolute;
		bottom: 130px;
	}
	@include tablet{
		height: 440px;
		background-size: 800px;
		.hole-wrap {
			bottom: 70px;
		}
	}
	.c-mole_box{
		top: inherit;
		bottom: 390px;
		@include tablet{
			top: inherit;
			bottom: 190px;
		}
	}
	.footer-svg{
		position: absolute;
		@include trans-x();
		top: 224px;
		left: calc(50% - 87px);
		z-index: 0;
		width: 690px;
		img{
			width: 100%;
		}
		@include tablet{
			width: 320px;
			left: calc(50% - 41px);
			top: 185px;
		}
	}
	.footer-svg2{
		position: absolute;
		top: 280px;
		left: 205px;
		z-index: 2;
		width: 260px;
		opacity: 0;
		transition: opacity 0.3s;
		&.is-active{
			opacity: 1;
		}
		img{
			width: 100%;
		}
		@include tablet{
			width: 140px;
			top: 201px;
			@include trans-x();
			left: calc(50% - 63px);
		}
	}
}