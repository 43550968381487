.l-terms{
  @extend .l-contact;
  .c-title-mid{
    margin-bottom: 30px;
  }
  .c-lead{
    text-align: left;
  }
  .c-btn{
    margin: 40px auto 0;
    max-width: 250px;
  }
  .inner-small{
    counter-reset: section;
  }
  .c-terms-list{
    counter-reset: child;
    dt{
      font-size: 1.6rem;
      margin: 20px 0;
      font-weight: 600;
      &:before{
        counter-increment: section;
        content: '第'counter(section)"条";
        margin-right: 1em;
      }
    }
    dd{
      color: #333;
      padding-bottom: 10px;
      padding-left: 2em;
      position: relative;
      &:before{
        counter-increment: child;
        content: counter(child)".";
        position: absolute;
        left: 0;
      }
    }
    ul.number{
      counter-reset: list;
      margin: 10px 0;
      & > li{
        position: relative;
        padding-left: 2em;
        margin-bottom: 5px;
        &:before{
          counter-increment: list;
          content: counter(list)".";
          position: absolute;
          left: 0;
        }
      }
    }

    ul.dot{
      margin: 10px 0;
      li{
        position: relative;
        padding-left: 1em;
        &:before{
          position: absolute;
          left: 0;
          content:"・";
        }
      }
    }
    ol{
      margin: 10px 0;
      counter-reset: count;
      li{
        position: relative;
        padding-left: 1.8em;
        margin-bottom: 5px;
        &:before{
          counter-increment: count;
          content: counter(count)" )";
          position: absolute;
          left: 0;
        }
      }
    }
  }
  .last{
    text-align: right;
    margin: 20px 0 0;
  }
  .last-date{
    margin-bottom: 40px;
    text-align: right;
  }
}
