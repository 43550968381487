@charset "UTF-8";

%option_module_manual {
  /*
    ------------------------------------
    ####################################
     option_module
     ※ projectの基本設定
    ####################################
    ------------------------------------
  */
}

//  ------------------------------------
//   MediaScreen指定
//  ------------------------------------

$xl: 1200px; //site Size
$l: 1080px; //inner Size
$m: 980px;
$s: 768px; // tablet
$xs: 360px; // SP


//  ------------------------------------
//   ベースプロパティ
//  ------------------------------------

$bg-color: #fff;
$color00: #ec6d74;
$color01: #d4efff;
$color02: #acdefe;

$color03: #fef5f5;
$color04: #f7f5f5;
$color05: #fef5f5;
$color06: #E8E8E8;
$color07: #eff8ff;
$color08: #f5c000;
$color09: #d2a903;

$bg-color01: #b8e4fe;
$bg-color02: #4390fe;

$white: #fff;
$black: #000000;
$base-black:#000000;
$gray: #ccc;
$base-gray: #EEEEEE;

$font-color: #000000;
$line-height: 1.6;
$a-color: #444;
$basefont: 14px;


//  ------------------------------------
//   CSSからのIMGロード
//  ------------------------------------
$dir : "../img/";
$font-dir : "../font/";


//  ------------------------------------
//   FONT指定
//  ------------------------------------


@mixin fo-min {
  //ヒラギノ明朝
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

  //遊明朝
  //font-family: "Yu Mincho", "YuMincho",'Sawarabi Mincho',"ヒラギノ明朝 ProN W6", "HiraMinProN-W6", serif;
}

//ゴシック
@mixin fo-go {
  //ヒラギノ角ゴ
  font-family: 'Noto Sans JP','ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 500;
  //遊ゴシック
  //font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  //font-weight: 500;
}
@mixin fo-go-web{
  font-family: brandon-grotesque-lignt, "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

//  webフォント
@mixin web-font01 {
  font-family: 'Noto Sans JP', sans-serif;
}

@mixin web-font02 {
  font-family: 'Merienda', cursive;
}

//  ------------------------------------
//   FORM設定
//  ------------------------------------

//RadioBox円形サイズ

$radioSize: 13px;
$radioSizeSmall: $radioSize - 2px;
$formItemsColor: #333;
$inputBorder: #ccc;
$selectArrowColor: #333;



//  ------------------------------------
//   Responsive指定
//  ------------------------------------

// Sitesize
@mixin siteSize {
  @media screen and (max-width: $xl) {
    @content;
  }
}
@mixin innerSize {
  @media screen and (max-width: $l) {
    @content;
  }
}

@mixin innerSizeSmall {
  @media screen and (max-width: $m) {
    @content;
  }
}
// tablet
@mixin tablet {
  @media screen and (max-width: $s) {
    @content;
  }
}

// Sphone
@mixin sphone {
  @media screen and (max-width: $xs) {
    @content;
  }
}

// 任意のサイズ
@mixin queryDown($query) {
  @media screen and (max-width: $query) {
    @content;
  }
}

@mixin queryUp($query) {
  @media screen and (min-width: $query) {
    @content;
  }
}
