.c-console-modal{
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  transition-property: opacity,visibility;
  position: relative;
  z-index: 500;
  &.is-modal_view{
    opacity: 1;
    visibility: visible;
  }
  .console-modal-overlay{
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(#000,.6);
    z-index: 500;
  }
  .modal-inner{
    @extend .inner-small;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    min-height: 50px;
    z-index: 501;
    background: $white;
    position: fixed;
    border-radius: 10px;
    @include trans-xy;
  }
  .c-lead{
    font-size: 1.6rem;
  }
  .c-caution{
    margin-top: 10px;
    position: relative;
    padding-left: 1em;
    font-weight: 300;
    color: #444;
    font-size: 1.4rem;
    &:before{
      content: '・';
      position: absolute;
      left: 0;
    }
  }
  .modal-btns{
    margin-top: 20px;
    font-size: 1.6rem;
    @include flex;
    justify-content: center;
    .no,.yes{
      padding: 5px 25px;
      margin: 0 15px;
      border-radius: 5px;
      color: $white;
      cursor: pointer;
      transition: opacity .3s;
      &:hover{
        opacity: .6;
      }
    }
    .no{
      background: #999;
    }
    .yes{
      background: #4380b7;
    }
  }

}