.l-myPage{
  padding-bottom: 100px;
  @extend %form-reset;
  @extend %form;
  input[type='text']{
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 300;
    @include tablet{
      padding: 10px;
    }
  }
  .c-title-en{
    position: absolute;
    top: -105px;
    z-index: 50;
    span{
      padding-left: 0;
    }
    span:before{
      content: none;
    }
  }

  .user {
    position: absolute;
    z-index: 100;
    right: 0;
    top:-100px;
    font-size: 1.2rem;
    padding: 10px 20px;
    background: #eee;
    border-radius: 5px;
    @include flex;
    dt {
      position: absolute;
      left: 15px;
      top: 9px;
      width: 15px;
      img{
        width: 100%;
      }
    }

    dd {
      box-sizing: border-box;
      padding-left: 22px;
    }

    @include tablet {
      font-size: 1.0rem;
      dl {
        padding: 10px 15px;
      }
    }
  }
  @include tablet{
    .c-title-en{
      position: static;
    }
    .user {
      position: absolute;
      z-index: 100;
      right: 3%;
      top: 0;
    }
  }

  .copy-input{
    display: block;
    max-width: 350px;
    position: relative;
    cursor: pointer;
    background: $white;
    border-radius: 5px;
    input{
      position: relative;
      background: none;
      letter-spacing: 1.5px;
      z-index: 2;
      padding: 10px 40px 10px 20px;
      @include tablet{
        padding: 10px;
      }
    }
    .copy-ico{
      @include content(20px,20px);
      @include trans-y();
      right: 10px;
      background: url(/assets/img/common/ico-copy.svg) center center no-repeat;
      background-size: contain;
      z-index: 16;
      cursor: pointer;
    }
  }
  .closed-activation-arrow{
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top:20px;
    cursor: pointer;
    &:before{
      @include content(50%,50%);
      @include trans-xy(-45);
      left: 30%;
      border-right: 3px solid;
      border-bottom: 3px solid;
      border-color: $black;
      transition: transform .3s;
    }
    &.is-active{
      &:before{
        @include trans-xy(45);
        top:30%;
      }
    }
  }
  .activation-wrap{
    .delete_mole{
      width: 64px;
      height: 64px;
      overflow: hidden;
      position: absolute;
      right: 0;
      bottom: 0;


      &:after{
        content: '';
        display: block;
        position: absolute;
        width: 120px;
        height: 70px;
        @include rotate(-45);
        right: -12px;
        bottom: 31px;

      }
      img{
        cursor: pointer;
        display: block;
        transition: opacity .3s;
        opacity: 1;
        &:hover{
          opacity: .6;
        }
      }
    }
  }
  .add-domain{
    text-align: right;
    color: $color00;
    position: relative;
    .add-btn{
      cursor: pointer;
      margin-bottom: 40px;
      display: inline-block;
    }
    .add-btn:before{
      content:'+';
      font-weight: 300;
      margin-right: 10px;
    }
    .add-domain_wrap{
      margin: 0 0 40px;
      padding: 10px 40px 20px;
      background: $base-gray;
      border-radius: 5px;
      display: block;
      @keyframes opacity_key {
        0% {
          top:20px;
          opacity: 0;
        }
        100% {
          top:0;
          opacity: 1;
        }
      }
      .c-btn_accept{
        margin: 10px auto 0;
      }
      .input-mole-list{
        //max-width: 100px;
        //display: inline-block;
        input{
          display: inline-block;
          max-width: 200px;
          background: $white;
          margin:0 10px;
          border: $gray solid 1px;
        }
      }
      @include tablet{
        padding: 10px 20px 20px;
      }
    }
  }
  .c-btn_app{
    margin: 40px auto 0;
  }
  .get-mole-wrap{
    &.is-none{
      display: none;
    }
  }
  .get-mole{
    padding:80px 0;
    p.txt{
      font-size: 220%;
      text-align: center;
      color: #888;
    }
  }
  .c-cbt {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top:0;
    overflow: hidden;
    p{
      width: 200%;
      position: absolute;
      @include trans-xy(-45);
      @include box-shadow();
      top: 35%;
      left: 35%;
      background: $black;
      color: $white;
      font-size: 1.1rem;
      text-align: center;
    }
  }
  .expiration{
    font-size: 1.2rem;
    margin-top: 10px;
  }
  .view-mole{
    padding: 10px 40px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 40px;
    position: relative;
    @include tablet{
      padding: 40px 20px;
    }
    &.is-none{
      display: none;
    }
    .mole-close{
      display: block;
      cursor: pointer;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background: $color00;
      position: absolute;
      top: -15px;
      right: -15px;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
      &:before,&:after{
        @include content(65%,2px,$white);
      }
      &:before{
        @include trans-xy(45)
      }
      &:after{
        @include trans-xy(-45)
      }
    }
    .plan_title{
      background: #999;
      text-align: center;
      position: absolute;
      right: 70px;
      top:0;
      color: $white;
      width: 147px;
      padding: 5px 0 7px;
      border-radius: 0 0 7px 7px;
      box-shadow: 0 2px 4px 0 rgba(#000,.2);
      @include tablet{
        right: inherit;
        @include trans-x();
      }
    }
    &.is-Free{
      background: #F4F4F4;
      .plan_title{
        background: #A0AEC3;
      }
      .closed-activation-arrow:before{
        border-color: #A0AEC3;
      }
    }
    &.is-Public{
      background: #D6EAF7;
      .plan_title{
        background: #5396E3;
      }
      .closed-activation-arrow:before{
        border-color: #5396E3;
      }
      .input-mole-list dt{
        color: #3E7EA5;
      }
    }
    &.is-Cloud{
      background: #E2F4EC;
      .plan_title{
        background: #1DA85D;
      }
      .closed-activation-arrow:before{
        border-color: #1DA85D;
      }
      .input-mole-list dt{
        color: #3E7EA5;
      }
    }
    &.is-EnterPrise{
      background: #F3F4E2;
      .plan_title{
        background: #FDCE00;
      }
      .closed-activation-arrow:before{
        border-color: #D9B860;
      }
      .input-mole-list dt{
        color: #3E7EA5;
      }
    }
  }

  .input-mole-list{
    @include flex;
    justify-content: space-between;
    text-align: left;
    align-items: flex-start;
    color: $black;
    .closed-activation{
      width: 100%;
      @include flex;
      justify-content: space-between;
      &.is-hidden{
        display: none;
      }
    }
    dt,dd{
      padding: 10px 0;
    }
    dt{
      font-weight: 400;
      width: 130px;
      text-align: right;
      @include tablet{
        text-align: left;
      }
    }
    dd{

      font-weight: 300;
      width:calc(100% - 160px);
    }
    .input-dt{
      padding-top: 17px;
    }
    .contentsName{
      color: #888;
    }
    .moleName{
      color: $color00;
      font-weight: 500;
      margin-left: 10px;
    }

    .mole-login,.mole-date{
      font-size: 90%;
      color: #666;
    }
    .mole-login{
      font-weight: 500;
      &:after{
        content: ":";
        margin:0 .5em;
      }
    }
    .mole-date{

    }
    @include tablet{
      dt,dd{
        width: 100%;
      }

    }
    .c-switch{
      margin-right: 20px;
    }
  }
  .c-status{
    @include flex;
  }
  .c-btn-lists{
    margin-top: 60px;
    @include flex;
    justify-content: center;
    .c-btn_submit{
      margin: 0;

      max-width: inherit;
      width: 320px;
    }
    .c-btn_app{
      margin: 0;
      margin-right: 20px;
      width: 320px;
      max-width: inherit;
    }
    @include tablet{
      .c-btn_app{
        margin: 0 auto 20px;
      }
    }
  }

}


#formState{
  .c-message{
    display: none;
    justify-content: center;
    align-items: center;
    margin: 5px 0 20px;
    color: #000;
    .message{
      text-align: left;
    }
    .message.is-none,.message-length{
      display: none;
    }
    .message-length.is-view{
      display: block;
    }

    &.is-view{
      @include flex;

    }
  }
  .moleName-input{
    position: relative;
    display: inline-block;
    input[type="text"]{
      padding-right: 35px;
    }
    .ico-state{
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      @include trans-y();
      right: 20px;
      background: center center no-repeat;
      background-size: contain;
    }
  }
  &.is-success{
    .moleName-input {
      position: relative;
      .ico-state {
        background-image: url('/assets/img/common/ico-success.png');
      }
    }
    .c-message{
      color: #2d9766;
    }
  }
  &.is-error{
    .moleName-input input{
      background-color: #ffd6d4;
    }
    .moleName-input{
      .ico-state{
        background-image: url('/assets/img/common/ico-error.png');
      }
    }
    .c-message{
      color: #fe1213;
    }
  }
  &.is-loading{
    .moleName-input{
      .ico-state{
        background-image: url('/assets/img/common/ico-loading.png');
        animation: spin 1.5s linear infinite;
        top: 9px;
      }
    }
    .c-message{
      .message{
        display: none;
      }
    }
  }
  @keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }
}

