.l-footer{
	background: $bg-color01;
	padding-bottom: 95px;
	@include tablet{
		padding-bottom: 40px;
	}

	.footer-nav{
		font-size: 1.2rem;
		color: #666;
		@include flex;
		margin-bottom: 10px;
		li{
			margin-right: 30px;
		}
	}
	.copy{
		font-size: 1.2rem;
		@include tablet{
			font-size: 1rem;
		}
	}
	.withdrawal{
		cursor: pointer;
		position: absolute;
		right: 0;
		top:0;
		background: #4E9DC7;
		border-radius: 20px;
		padding: 5px 20px 5px 30px;
		color: $white;
		font-size: 1.2rem;
		@include box-shadow();
		transition:opacity .3s;
		&:hover{
			opacity: .6;
		}
		&:before{
			@include content(12px,10px);
			@include trans-y;
			left: 10px;
			background: url(/assets/img/common/ico-arrow.svg) no-repeat center center;
			background-size: contain;
		}
	}
}