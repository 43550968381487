.l-app{
  padding: 60px 0 40px;
  @include tablet{
    padding:40px 0;
  }

  .c-lead{
    color: #777;
  }
  .version{
    margin-top: 20px;
    text-align: center;
    color: #777;
    a{
      color: #6F95C9;
    }
  }
  .c-title-big{
    font-weight: 600;
  }
  .mole-icon{
    max-width: 350px;
    margin: 40px auto;
    img{
      width: 100%;
    }
    @include tablet{
      max-width: 280px;
    }
  }
  .dl-btn-list{
    @include flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    max-width: 700px;

    dl{
      width: 48%;
    }
    @include tablet{

      max-width: 400px;
      dl{
        width: 100%;
      }
    }
    dd{
      margin-top: 20px;
      margin-bottom: 40px;
      color: #666;
      font-weight: 300;
    }
    .btn-win,.btn-mac{

      a{
        border-radius: 5px;
        display: block;
        padding: 15px;
        font-size: 1.6rem;
        position: relative;
        padding-left: 40px;
        &:after{
          @include content(23px,23px);
          @include trans-xy();
          background: center center no-repeat;
          background-size: contain;
        }
      }
    }
    .btn-win a{
      background: #4e9dc7 ;
      color: $white;
      &:after{
        background-image: url(/assets/img/app/ico-win.svg);
        left: calc(50% - 80px);
      }
    }

    .btn-mac a{
      background: #ebebeb;
      &:after{
        width: 25px;
        height: 25px;
        background-image: url(/assets/img/app/ico-app.svg);
        left: calc(50% - 68px);
        top:calc(50% - 2px);
      }
    }
  }
}


