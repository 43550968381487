.l-header{
	@include flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	padding:46px 20px 20px;
	box-sizing: border-box;
	z-index: 300;
	transition: .3s;
	transition-property: padding,background-color;
	@include queryDown(1200px){
		min-width: $l;
	}
	@include tablet{
		min-width: inherit;
		padding: 20px;
	}
	.header_logo{
		width: 240px;
		position: relative;
		top: -8px;

		img{
			display: block;
			width: 100%;
		}
		@include queryDown(1200px){
			width: 190px;
			top: -4px;
		}
		@include tablet{
			width: 40%;
			max-width: 200px;
			top: -3px
		}
	}

	&.js-caution{
		@include tablet{
			top:100px;
		}
	}
	&.is-fixed{
		position: fixed;
		padding:15px 20px 15px;
		background: rgba(#fff,.6);
		.header_logo{
			width: 180px;
			@include tablet{
				width: 40%;
			}
		}
		&.js-caution{
			top:0;
		}
	}
	.nav-wrap{
		min-width: 880px;
		@include flex;
		justify-content: flex-end;
		align-items: center;
		@include queryDown(1200px){
			min-width: 840px;
			font-size: 1.2rem;
		}
		@include tablet{
			width: 60%;
			min-width: inherit;
		}
	}
	.c-btn_nav{
		margin-left: 30px;
		@include queryDown(1200px){
			margin-left: 20px;

		}
		@include tablet{
			margin-left: 0;
		}
	}
	.global-nav{
		@include flex;
		justify-content: flex-end;
		align-items: center;
		font-size: 1.3rem;
		transition: opacity 0s;

		.nav-list.nav-drop > a{
			padding: 0;
			padding-right: 12px;
			&:after{
				@include content(5px,5px);
				@include triangle(bottom,7,$black);
				top: 7px;
				right: 0;
				@include tablet{
					top: 10px;
				}
			}
		}
		@include queryUp($s){
			.nav-drop:hover .nav-drop-menu{
				opacity: 1;
				visibility: visible;
				top:calc(100% + 10px);
			}
		}
		.nav-list{
			position: relative;
			margin-left: 15px;
			font-weight: bold;
			a{
				padding: 10px 0;
				position: relative;
			}
			&:first-child{
				margin-left: 0;
			}
		}
		.nav-drop-menu{
			position: absolute;
			top:calc(100% + 20px);
			width: 240px;
			border-radius: 20px;
			visibility: hidden;
			opacity: 0;
			font-size: 90%;
			font-weight: normal;
			color: #368fb1;
			@include box-shadow();
			@include flex;
			background: $white;
			padding: 8px 20px;
			transition: .3s;
			transition-property: opacity,visibility,top;
			li:not(:last-child){
				margin-right: 15px;
			}
			a{
				color: #368fb1;
			}
			&:before{
				@include content(5px,5px);
				@include triangle(top,7,$white);
				bottom:100%;
				left: 50px;
			}

		}
		@include tablet{
			position: absolute;
			left: 0;
			top:0;
			display: block;
			box-sizing: border-box;
			padding-top: 130px;
			width: 100%;
			height: 100vh;
			opacity: 0;
			visibility: hidden;
			z-index: -1;
			background: $bg-color01 url(/assets/img/common/nav-bg.png) no-repeat center center;
			background-size: cover;
			transition: .3s;
			transition-property: opacity,visibility;
			&.is-active{

				visibility: visible;
				opacity: 1;
			}
			.nav-list{
				margin-left: 20px;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 1.6rem;
				&:first-child{
					margin-left: 20px;
				}
				span{
					position: relative;
					cursor:pointer;
					padding-right: 15px;
					&:after{
						@include content(5px,5px);
						@include triangle(bottom,7,$black);
						top: 7px;
						right: 0;
						@include tablet{
							top: 10px;
						}
					}
				}
			}
			.nav-drop-menu{
				display: none;
				&:before{
					content: none;
				}
				a{
					color: #444;
				}
				&.is-view{
					box-shadow: none;
					padding: 10px 0 0 10px;
					background: none;
					position: static;
					opacity: 1;
					visibility: visible;
					display: block;
				}
				li{
					margin-top: 5px;
				}
			}
		}
	}
	.nav-toggle{
		display: none;
		@include tablet{
			display: block;
			width: 30px;
			height: 30px;
			margin-left: 10px;
			position: relative;
			span{
				position: absolute;
				display: block;
				width: 90%;
				height: 3px;
				background: $black;
				@include trans-xy();
				transition: .3s;
				transition-property: opacity,transform,left;
				opacity: 1;
				&:nth-child(1){
					top:20%;
				}
				&:nth-child(2){
				}
				&:nth-child(3){
					top:80%;
				}
			}
			&.is-active{
				span{
					&:nth-child(1){
						@include trans-xy(45);
					}
					&:nth-child(2){
						left: calc(50% + 5px);
						opacity: 0;
					}
					&:nth-child(3){
						@include trans-xy(-45);
					}
				}
			}
		}
	}
}