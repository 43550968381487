.l-login{
  @extend .l-contact;
  .no-account{
    text-align: center;
    display: block;
    margin: 20px auto 40px;
    width: 100%;
  }
  .c-btn{
    margin: 40px auto 0;
    max-width: 250px;
  }
  .c-btn_submit{
    margin: 40px auto 0;
    display: block;
    width: 100%;
  }

}


