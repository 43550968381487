@charset "UTF-8";
/* CSS Document */
@import "../common/extend";

/*
  ------------------------------------
  ####################################
   #Common style
  ####################################
  ------------------------------------
*/


$dir-common: $dir + "common";
/* Reset */
@include reset;
html {
	background: #fff;
	font-size: 10px;
	font-feature-settings: 'palt';
}

body {
	font-size: 1.4rem;
	min-width: $l;

	&.is-scroll {
		overflow: hidden;
	}

	@include tablet {
		font-size: 1.2rem;
		min-width: inherit;
		width: 100%;
	}
	&.on-load{
		visibility: hidden;
		opacity: 0;
		transition: .6s;
		transition-property: opacity,visibility;
		&.is-view{
			visibility: visible;
			opacity: 1;
		}
	}
}

.wrap {
	overflow: hidden;
	width: 100%;
	@include fo-go;
}

body {
	width: 100%;
	@include fo-go;
	line-height: $line-height;
	font-size: $basefont;
	color: $font-color;
	letter-spacing: 1px;

	&.is-hidden {
		overflow: hidden;
	}
}

a {
	text-decoration: none;
	color: $black;
	transition: opacity .3s;

	&:hover {
		opacity: .6;

	}
}

a img {
	transition: opacity .3s;
}

a:hover img {
	opacity: .8;
}

.inner-wide {
	max-width: $l;
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	@include innerSize {
		padding: 0 3%;
	}
	@include tablet {
		width: 100%;
	}
}

.inner {
	max-width: $m;
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	@include tablet {
		padding: 0 3%;
		width: 100%;
	}
}

.inner-small {
	max-width: 800px;
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	@include tablet {
		padding: 0 3%;
		width: 100%;
	}
}


.pc {
	display: block;
}

.pc-flex {
	display: flex;
}

.pc-ib {
	display: inline-block;
}

.sp,
.sp-flex,
.sp-ib {
	display: none;
}


@include tablet {
	.sp {
		display: block;
	}
	.sp-flex {
		display: flex;
	}

	.sp-ib {
		display: inline-block;
	}

	.pc,
	.pc-flex,
	.pc-ib {
		display: none;
	}
}

.txt {
	font-size: 1.4rem;
	@include tablet {
		font-size: 1.2rem;
	}
}

.txt-link {
	color: $color00;
	text-decoration: underline;
	&_arrow{
		color: $color00;
		position: relative;
		//padding-left: 1.5em;
		box-sizing: border-box;
		&:before{
			content: '→';
			margin-right: 10px;
		}
	}
}

.c-lead {
	text-align: center;
	line-height: 1.8;

	@include tablet {
		//text-align: left;
	}
}

.pagetop {
	img {
		width: 100px;
	}
}

.c-mole_box {
	width: 140px;
	position: absolute;
	@include trans-x();
	z-index: 5;
	top: 380px;
	transition: 0.3s;
	transition-property: top,bottom;
	img {
		width: 100%;
		display: block;
	}

	&.is-waiting {
		animation:waiting 3s infinite ease-in-out alternate;
	}
	&.is-fallDownStop{
		opacity: 0;
	}
	&.is-falling {
		transform: translate(-50%, -500px);
		transition: .7s transform cubic-bezier(0.25, 0.46, 0.45, 0.94);
		&.js-active{
			transform: translate(-50%, 0) rotate(-1deg);
		}
	}



	&.is-fallDown {
		animation:fallDown .7s cubic-bezier(0.55, 0.06, 0.68, 0.19) forwards;
	}

	@include tablet {
		width: 75px;
		top: 220px;
	}
}

@keyframes waiting {
	0% {
		transform: translate(-50%, 0) rotate(-1.5deg);
	}
	50% {
		transform: translate(-50%, -10px) rotate(0deg);
	}
	100% {
		transform: translate(-50%, 0) rotate(1.5deg)
	}
}

@keyframes falling {
	0% {
		transform: translate(-50%, -350px);
	}
	100% {
		transform: translate(-50%, 0);
	}
}

@keyframes fallDown {
	0% {
		transform: translate(-50%, 0);
	}
	20%{
		transform: translate(-50%, -80px);
	}
	100% {
		transform: translate(-50%, 500px);
	}
}