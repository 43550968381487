.c-btn{
  text-align: center;
  font-size: 1.4rem;
  max-width: 320px;

  a{
    display: block;
    background: $color00;
    color: $white;
    padding: 15px;
    border-radius: 40px;
    position: relative;
  }
  @include tablet{
    font-size: 1.2rem;
    max-width: 200px;
    a{
      padding: 10px;
    }
  }
}

.c-btn_main{
  @extend .c-btn;
  font-size: 1.8rem;

  @include tablet{
    font-size: 1.4rem;
  }
}
.c-btn_back{
  @extend .c-btn_main;
  a{
    background: $black;
  }
}
.c-btn_app{
  @extend .c-btn_main;
  a{
    background: $color09;
  }
}
.c-btn_dl{
  @extend .c-btn_main;
  a{
    padding-left: 40px;
    position: relative;
    &:before{
      @include content(25px,25px);
      @include trans-y();
      left: 30px;
      background: url(/assets/img/common/ico-dl.svg) no-repeat center center;
      background-size: contain;
    }
  }
}

.c-btn_small{
  @extend .c-btn;
  font-size: 1.4rem;
  a{
    padding: 10px;
  }
  @include tablet{
    font-size: 1.2rem;
  }
}
.c-btn_nav{
  @extend .c-btn;
  max-width: 180px;

  width: 100%;
  font-size: 1.3rem;
  @include queryDown(1200px){
    max-width: 150px;
    a{
      padding: 10px;
    }
  }
  @include tablet{
    min-width: 130px;
    font-size: 1.2rem;
  }
}
.c-btn_confirm{
  @extend .c-btn_main;
  width: 100%;
  span{
    cursor: pointer;
    display: block;
    background: $color00;
    color: $white;
    padding: 15px;
    border-radius: 40px;
    position: relative;
    @include tablet{
      padding: 10px;
    }
  }
}
.c-btn_reset{
  @extend .c-btn_main;
  width: 100%;
  max-width: 200px;
  span{
    cursor: pointer;
    display: block;
    background: $white;
    box-sizing: border-box;
    border: 1px solid #666;
    padding: 15px;
    border-radius: 40px;
    position: relative;
    transition: .3s opacity;
    &:hover{
      opacity: .6;
    }
    @include tablet{
      padding: 10px;
    }
  }
}
.c-btn_submit{
  @extend .c-btn_main;
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
  label{
    width: 100%;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    background: $color00;
    color: $white;
    padding: 15px ;
    border-radius: 40px;
    position: relative;
    @include tablet{
      padding: 10px;
    }
    transition: .3s opacity;
    &:hover{
      opacity: .6;
    }
  }
  input{
    display: none;
  }
}

.c-btn_accept{
  @extend .c-btn;
  width: 100%;
  max-width: 170px;
  box-sizing: border-box;
  label{
    width: 100%;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    background: $color00;
    color: $white;
    padding: 5px ;
    border-radius: 40px;
    position: relative;
    @include tablet{
      padding: 5px;
    }
    transition: .3s opacity;
    &:hover{
      opacity: .6;
    }
  }

  #acceptBtn{
    opacity: .6;
    pointer-events: none;
    &.is-active{
      opacity: 1;
      pointer-events: inherit;
    }
  }
  input{
    display: none;
  }
}

.c-btn_txt{
  a{
    padding: 0 10px 5px 20px;
    border-bottom: 2px solid $black;
    position: relative;
    line-height: 0;
    &:before{
      @include content(5px,5px);
      @include trans-y(45);
      top:calc(50% - 2px);
      left: 3px;
      border-top: 2px solid $black;
      border-right: 2px solid $black;
      transition: .3s left;
    }
    &:hover{
      &:before{
        left: 6px;
      }
    }
  }

}

.c-btn_anchor{
  @extend .c-btn;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  width: 100%;
  right: -60px;
  bottom: 100px;
  @include siteSize{
    right: 20px;
  }

  a{
    background: $white;
    color: $black;
    text-align: left;
    padding-left: 60px;
    @include box-shadow(0);
  }
  .anchor_icon{
    display: block;
    background: url("/assets/img/common/slack.svg") center center;
    background-size: contain ;
    position: absolute;
    left: 15px;
    width: 28px;
    height: 28px;
    @include trans-y();
  }
  .woman-icon{
    width: 70px;
    position: absolute;
    bottom:0;
    right: 15px;

    img{
      width: 100%;
      display: block;
    }
  }
  @include tablet{
    font-size: 1.2rem;
    bottom:25px;
    z-index: 60;
    max-width: 240px;
    a{
      padding-left: 40px;
    }
    .anchor_icon{
      left: 10px;
      width: 20px;
      height: 20px;
      @include trans-y();
      &:after{
        top:6px;
        width: 5px;
        height: 5px;
        display: block;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
      }
    }
    .woman-icon{
      width: 50px;
      right: 5px;
    }
  }
}

.c-pageTop{
  position: fixed;
  bottom:30px;
  right: 30px;
  width: 60px;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  transition-property: visibility,opacity;
  &.is-view{
    opacity: 1;
    visibility: visible;
  }
  img{
    width: 100%;
    display: block;
  }
  @include tablet{
    bottom:20px;
    right: 20px;
    width: 40px;
  }
}

.c-switch{
  position: relative;
  height: 20px;
  width: 48px;
  input{
    display: none;
    &:before,&:after{

    }
  }
  .swing{
    background: #777;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    padding-left: 0 !important;
    box-sizing: border-box;
    &:before,&:after{
      content: none !important;
      transform: none !important;
    }
    &:before{
      color: rgba($white,.6);
      position: absolute;
      line-height: 1;
      letter-spacing: 0;
      border: none !important;
      left: 22px !important;
      top: 4px !important;
      font-size: 80%;
      content:"OFF" !important;
    }
    .swing-switch{
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      background: rgba($white,.6);
      border-radius: 50%;
      top:2px;
      left: 2px;
      transition: left .15s;
    }
  }
  input:checked + .swing{
    background: #25b74c;
    &:before{
      color: $white;
      left: 10px !important;
      content:"ON" !important;

    }
    .swing-switch{
      background: $white;
      left: 30px;
    }
  }
}