.l-up{
  @extend .l-contact;
  .no-account{
    text-align: center;
    display: block;
    margin: 20px auto 40px;
    width: 100%;
  }

  .c-btn_submit{
    margin: 40px auto 0;
    display: block;
    width: 100%;
  }


  .c-steplist{
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    margin: 40px 0 40px 0;
    li{
      width: 22%; box-sizing: border-box;
      background: #F4F4F4; padding: 30px 15px 20px 15px;
      position: relative;
      .step{
        background: #FFA700;
        padding: 4px 8px; display: inline-block;
        position: absolute; left: 50%; top:0;
        transform: translate3d(-50%,-50%,0);
      }
      h2{
        font-weight: bold;
        text-align: center;
        font-size: 130%;
        margin-bottom: 20px;
      }
      .small-1,.small-2{
        font-size: 10px; color: rgb(132, 132, 132);
        line-height:1.6; display: block; margin-top:10px;
      }
      .small-2{
        margin-bottom:15px;
      }
      img{
        display: block; margin:0 auto;
      }
      .img-step1{
        max-width: 120px; width: 80%;
        margin-bottom:15px;
      }
      .img-step2{
        width: 100%;
        margin-bottom:15px;
      }
      .img-step3{
        width: 70%;
        margin-bottom:15px;
      }
      .img-step4{
        width: 100%;
      }
      .button{
        margin-top: 20px;
        position: absolute;
        position: absolute; left: 50%; bottom:15px;
        transform: translate3d(-50%,0%,0);
      }
      &:before{
        content: "▼";
        position: absolute; top:50%; right: -30px;
        transform: translate3d(0,-50%,0) rotate(-90deg);
        color: #EDACAC;
      }
      &:last-child{
        &:before{
          content: none;
        }
      }
    }
    @include tablet{
      display: block;
      li{
        width: 100%;
        .button{
          position: relative;
          margin-top: 35px;
        }
        margin-bottom: 40px;
      }
    }
  }
  .button{
    background: #EC6D74;
    color: #FFF;
    width: 80%;
    max-width:324px;
    display: block;
    text-align: center;
    color: #FFF;
    border-radius: 30px;
    padding: 0.6em 0;
  }
  .button.outside{
    margin: 0 auto;
    font-size: 130%;
  }
}

