@charset "UTF-8";
@import "option";
@import "mixin";

%extend_module_manual {
  /*
    ------------------------------------
    ####################################
     extend_module
     ※ extend用共通モジュール
    ####################################
    ------------------------------------
  */
}

%ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

%hidetext {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

// Form-reset

%form-reset {

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  button,
  textarea,
  select {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    font-size: 100%;
    -webkit-appearance: none;
    appearance: none;
  }

  ::placeholder {
    color: #ccc;
  }

  select::-ms-expand {
    display: none;
  }

  input[type="checkbox"], input[type="radio"] {
    display: none;
  }

  input[type="checkbox"], input[type="radio"] {
    display: none;

    & + label {
      cursor: pointer;
    }
  }
}


%form {

  font-size: 1.4rem;
  .select {
    border-radius: 10px;
    box-sizing: border-box;
    background: $base-gray;
    margin-top: -2px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100%;

    &:before {
      @include content(7px, 7px);
      border-right: 1px solid $selectArrowColor;
      border-bottom: 1px solid $selectArrowColor;
      @include trans-y(45);
      top: 45%;
      right: 20px;
      z-index: 0;
      cursor: pointer;
    }

    select {
      width: 100%;
      height: 100%;
      padding: 18px 40px 18px 18px;
      position: relative;
      z-index: 1;
      line-height: 1;
      box-sizing: border-box;

    }
  }

  input[type="text"],input[type="email"],input[type="tel"] {
    //border: 1px solid $inputBorder;
    background: $base-gray;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 18px;
    display: block;
    width: 100%;
    &::placeholder {
      color: #888;
    }
  }
  textarea{
    //border: 1px solid $inputBorder;
    border-radius: 10px;
    background: $base-gray;
    box-sizing: border-box;
    padding: 18px;
    display: block;
    width: 100%;
    resize: vertical;
    &::placeholder {
      color: #888;
    }
  }

  input[type="checkbox"],input[type="radio"] {
    & + label {
      padding-left: 23px;
      position: relative;
      &:after{
        transition:background-color 0.3s;
        @include content(20px, 20px, #fff);
        @include trans-y;
        left: 0;
        border-radius: 3px;
      }
      &:before {
        transition:background-color 0.3s;
        @include content(0, 0);
        @include trans-y(45);
        left: 0;
      }
    }

    &:checked + label:after{
      @include content(20px, 20px,$white);
      @include tablet{
        width: 20px;
        height: 20px;
      }
    }
    &:checked + label:before {
      @include content(8px, 18px);
      @include trans-y(45);
      left: 6px;
      border: none;
      background: none;
      border-bottom: 4px solid $color00;
      border-right: 4px solid $color00;
      border-radius: 0;
      z-index: 1;
      top: 3px;
    }
  }
  input[type="checkbox"] {
    & + label {
      margin-right: 15px;
      &:after {
        background-color: #ddd;
      }
    }
    &:checked + label:after {
      background-color: #ddd;
    }
  }
  //input[type="radio"] {
  //  & + label {
  //    padding-left: 30px;
  //    position: relative;
  //
  //    &:before {
  //      transition: 0.3s;
  //      @include content($radioSize, $radioSize, #fff);
  //      @include trans-y;
  //      left: 0;
  //      border-radius: 50%;
  //      border: 1px solid #888;
  //    }
  //
  //    &:after {
  //      @include content($radioSizeSmall, $radioSizeSmall, #fff);
  //      left: 2px;
  //      transition: 0.3s;
  //      opacity: 0;
  //      @include trans-y(0, 0);
  //      border-radius: 50%;
  //      z-index: 1;
  //    }
  //  }
  //
  //  &:checked + label:after {
  //    @include content($radioSizeSmall, $radioSizeSmall, $formItemsColor);
  //    left: 2px;
  //    @include trans-y(0, 1);
  //    border-radius: 50%;
  //    opacity: 1;
  //    z-index: 1;
  //  }
  //
  //}

  @include tablet {
    //iPhoneコンテンツ拡大防止のため16px固定
    input[type="text"],input[type="tel"],input[type="email"],
    textarea,
    select {
    }
  }
}
