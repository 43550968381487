.hole-wrap{
  position: absolute;
  width: 220px;
  @include trans-x();
  bottom: 156px;
  &.layer1{
    z-index: 1;
  }
  &.layer2{
    z-index: 30;
  }
  &.layer3{
    z-index: 50;
    opacity: .4;
  }
  img{
    display: block;
    width: 100%;
  }
  .hole{
    position: absolute;
    bottom:0;
  }
  .hole-wall{
    position: absolute;
    bottom: -156px;
    width: 195px;
    left: 12px;
  }
  @include tablet{
    @include trans-x(0,0.55);
    bottom: 86px;
  }
}


.l-about{
  background: $white;
  overflow: hidden;
  padding: 40px 0 50px;

  .about-box{
    padding: 40px;
    background: $color03;
    border-radius: 10px;
    @include flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  .img{
    width: 240px;
    img{
      width: 100%;
      display: block;
    }
  }
  .detail{
    width: calc(100% - 260px);

    .detail-title{
      font-size: 2.4rem;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .txt{
      line-height: 1.8;
    }
  }

  .img-list{
    max-width: 800px;
    margin: 40px auto;
    img{
      display: block;
      width: 100%;
    }
  }
  .server_lists{
    margin: 40px auto;
    @include flex;
    justify-content: space-around;
    max-width: 800px;
    .list-item{
      text-align: center;
      width: 13%;
      img{
        width: 100%;
        display: block;
      }
      .txt{
        font-size: 1.2rem;
        margin-top: 20px;
      }
    }
  }
  @include tablet{
    .about-box{
      padding: 40px 20px;
      margin-bottom: 40px;
    }
    .img{
      width: 50%;
      margin:0 auto 40px;
      order: 1;
      img{
        width: 100%;
        display: block;
      }
    }
    .detail{
      order: 2;
      width: 100%;

      .detail-title{
        font-size: 1.6rem;
      }
    }
    .c-lead{
      text-align: left;
    }
  }
}

.l-happen{
  padding-top: 40px;
  .flex-list{
    @include flex;
    justify-content: space-between;
    margin-top: 60px;
    .list-item{
      width: 48%;
      margin-bottom: 60px;
      .img{
        overflow: hidden;
        border-radius: 10px;
        img{
          width: 100%;
          display: block;
        }
      }

      .detail{
        margin-top: 25px;
        .sub-title{
          display: block;
          color: $color00;
          margin-bottom: 5px;

        }
        .txt{
          line-height: 1.8;
          margin-top: 20px;
          @include tablet{
            margin-top: 10px;
          }
        }
      }
    }
    @include tablet{
      margin-top: 0;
      .list-item{
        max-width: 500px;
        margin: 0 auto 40px;
        width: 100%;

        .detail{
          margin-top: 25px;
        }
      }
    }
  }
}

.l-merit{
  padding-top: 40px;
  .flex-list{
    @include flex;
    justify-content: space-between;
    .img{
      overflow: hidden;
      border-radius: 10px;
      img{
        width: 100%;
        display: block;
      }
    }
    .list-item{
      width: 30%;
      margin-top: 60px;
    }
    .detail{
      margin-top: 30px;
    }
    .txt{
      line-height: 1.8;
      margin:20px 0;
    }
    @include tablet{
      .list-item{
        width: 47%;
        margin-top: 40px;
      }
    }
  }
}

.l-case{
  padding-top: 40px;
  .interview-wrap{
    margin-top: 60px;
    .interview-list{
      @include flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 80px;
      &:nth-child(even){
        .img{
          order: 2;
        }
      }
    }
    .img{
      width: 38%;
      overflow: hidden;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }
    .interview-detail{
      width: calc(62% - 40px);
    }
    .interview-lead{
      font-weight: 700;
      font-size: 2.4rem;
      margin-bottom: 20px;
    }
    .job{
      font-weight: 700;
      margin-bottom: 40px;
    }
    .txt{
      font-size: 1.6rem;
      line-height: 1.8;
    }
    @include tablet{
      max-width: 500px;
      margin: 30px auto 0;
      .img{
        width: 100%;
        margin-bottom: 20px;
      }
      .job{
        margin-bottom: 20px;
      }
      .interview-detail{
        width: 100%;
        order: 2;
      }
      .interview-list {
        margin-bottom: 50px;
      }
      .interview-lead{
        font-weight: 700;
        font-size: 1.8rem;
        margin-bottom: 10px;
      }
      .job{
        font-weight: 700;
        margin-bottom: 20px;
      }
      .txt{
        font-size: 1.2rem;
      }
    }
  }
}

.l-point{
  .point-box{
    border: 2px solid $gray;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px;
    @include flex;
    justify-content: space-between;
    margin: 40px auto 60px;
    @include tablet{
      margin : 15px auto 40px;
      padding: 20px;
      border: 1px solid $gray;
      max-width: 500px;
    }
    .box-child {
      width: 30%;
      padding-top: 40px;

      h4{
        border-bottom: 2px solid $black;
        display: inline-block;
        padding: 0 5px 0;
        font-size: 2.4rem;
        font-weight: 900;
      }
      .txt-large{
        margin: 20px 0;
        font-weight: 900;
        font-size: 2.4rem;
        line-height: 1.4;
      }
      @include tablet{
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        h4{
          font-size: 1.6rem;
        }
        .txt-large{
          margin: 20px 0;
          text-align: center;
          font-size: 1.6rem;
        }
        .txt{
          text-align: center;
        }
      }
    }
    .box-child_before,.box-child_after{
      box-sizing: border-box;
      width: calc(35% - 4px);
      padding:40px 20px;
      border-radius: 20px;
      h4{
        font-size: 1.8rem;
        font-weight: 700;
        border-bottom: 2px solid $black;
        display: inline-block;
        margin-bottom: 30px;
      }
      .num-list{
        counter-reset: section;
        li:not(:last-child){
          margin-bottom: 10px;
        }
        li::before {
          counter-increment: section;
          content: counter(section)'.';
        }
      }
      @include tablet{
        width: 100%;
        padding: 20px;
        font-size: 1.2rem;
        margin-bottom: 5px;
        h4{
          font-size: 1.6rem;
          margin-bottom: 15px;
        }
        .num-list {
          li:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
    .box-child_before{
      background: $color04;
    }
    .box-child_after{
      background: $color05;
    }

    .one-point{
      width: 100%;
      margin-top: 40px;
      @include flex;
      align-items: flex-start;
      justify-content: space-between;
      dt{
        @extend .c-title-en;
        margin: 0;
        font-size: 2.4rem;
      }
      .txt{
        line-height: 1.8;
        width: calc(100% - 160px);
      }
      @include tablet{
        margin-top: 20px;
        dt,.txt{
          width: 100%;
        }
        dt{
          margin-bottom: 15px;
          font-size: 1.6rem;
        }
      }
    }
  }

  .logos-list{
    margin-top: 60px;
    @include flex;
    justify-content: space-between;
    li{
      width: calc(25% - 5px);
      margin-bottom: 50px;
      text-align: center;
      img{
        height: 40px;
        width: auto;
      }
    }
    @include tablet{
      justify-content: space-around;
      margin-top: 40px;
      li{
        margin-bottom: 30px;
        width: 31%;
        img{
          height: auto;
          width: 100%;
        }
      }
    }
  }
  @include tablet{
    .c-title-mid{
      font-size: 1.4rem;
    }
  }
}


.l-bnr-list{
  padding-bottom: 60px;
  @include tablet{
    padding-bottom: 0;
  }
  .bnr-box,.bnr-box_blue{
    margin-top: 80px;
    text-align: center;
    @include flex;
    justify-content: space-between;
    &.is-col1{
      .box-child{
        width: 100%;
      }
    }

    &.is-col2{
      .box-child{
        width: 50%;
        box-sizing:border-box;
        @include tablet{
          width: 100%;
        }
      }
    }
    @include tablet{
      margin-top: 60px;
    }
  }
  .box-child{
    padding: 40px;


  }
  .bnr-box{
    border: 1px solid $gray;
    h3{
      font-size: 2.4rem;
      margin-bottom: 10px;
    }
    h4{
      border-bottom: 2px solid #000;
      display: inline-block;
      padding: 0 5px 0;
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 20px;
      @include tablet{
        font-size: 1.6rem;
      }
    }
    &.is-col2 .box-child:nth-child(2){
      border-left: 1px solid $gray;
      @include tablet{
        border: none;
        border-top: 1px solid $gray;
      }
    }
  }
  .bnr-box_blue{
    background: $color07;
    &.is-col2 .box-child:nth-child(2){
      @include tablet{
        padding-top: 0;
      }
    }
  }
  .c-btn_main{
    margin: 40px auto 0;
    @include tablet{}
    margin-top: 20px;
  }

}

.mvFrame{
  display: none;
  &.js-active{
    display: block;
  }
}