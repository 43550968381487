.l-how_to{
  padding: 0 0 80px;
  .title-wrap{
    max-width: 800px;
    margin: 0 auto 40px;
    em{
      font-size: 1.2em;
      margin-right: .2em;
    }
    @include tablet{
      margin-bottom: 20px;
    }
  }
  .how_to-box{
    background: $color01;
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;

    .dl-wrap{
      @include flex;
      justify-content: space-around;
      text-align: center;
      margin-top: 40px;
      h2{
        font-weight: 300;
        margin-bottom: 10px;
        em{
          font-size: 2.0rem;
          font-weight: 500;
        }
      }
      .dl-item{
        width: 36%;
      }
    }
  }
  .how_to-box_sp{
    justify-content: center;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;
    max-width: 500px;
    margin: 0 auto;
    background: $color01;
    border-radius: 10px;
    .img{
      width: 65px;
      margin-right: 20px;
      img{
        width: 100%;
      }
    }
    .txt{
      font-size: 1.8rem;
    }
  }
}


.l-use{
  .flow-box{
    margin: 40px 0 0;
    padding: 40px;
    box-sizing: border-box;
    border: 1px solid $gray;
    border-radius: 10px;
    .img{
      max-width: 800px;
      margin: 40px auto 0;
      img{
        width: 100%;
      }
    }
    .flow-list{
      .step-wrap{
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 15px;
        .step{
          font-weight: 300;
          color: $color00;
          font-size: 1.1em;
          margin-right: 30px;
        }
      }
      .txt{
        line-height: 1.8;
      }
      &:not(:last-child){
        margin-bottom: 50px;
      }
    }

    @include tablet{
      padding: 20px;
      .img{
        margin: 20px 0 0;
      }
      .flow-list{
        .step-wrap{
          font-size: 1.8rem;
          font-weight: 900;
          margin-bottom: 15px;
          .step{
            font-weight: 300;
            color: $color00;
            font-size: 1.1em;
            margin-right: 0;
            margin-bottom: 5px;
            display: block;
            text-align: center;
          }
        }
        &:not(:last-child){
          margin-bottom: 30px;
        }
      }
    }
  }

}