.l-contact{
  padding-bottom: 40px;
  .title-wrap{
    max-width: 800px;
    margin: 0 auto 40px;
    em{
      font-size: 1.2em;
      margin-right: .2em;
    }
    @include tablet{
      margin-bottom: 20px;
    }
  }
  .box-thanks{
    .img{
      max-width: 500px;
      margin: 40px auto 40px;
      display: block;
      img{
        width: 100%;
      }
      @include tablet{
        width: 90%;
      }
    }
    .c-lead{
      font-weight: 700;
      line-height: 1.8;
      font-size: 1.6rem;
    }
    .c-btn_back{
      margin: 40px auto 0;
    }
  }

  .form-contact{
    @extend %form-reset;
    @extend %form;
    max-width: 600px;
    @include flex;
    justify-content: space-between;
    margin: 0 auto;

    .required{
      &.is-success{
        //input[type='text'],select,textarea{
        //  background: $color01;
        //}
        position: relative;
        &:after{
          content:'SUCCESS';
          display: block;
          position: absolute;
          bottom:calc(100% + 10px);
          right: 0;
          font-size: 1.1rem;
          padding:2px 5px;
          background: #0062ff;
          color: $white;
          border-radius: 5px;
        }
      }
      &.is-error{
        position: relative;
        &:after{
          content:'ERROR';
          display: block;
          position: absolute;
          bottom:calc(100% + 10px);
          right: 0;
          font-size: 1.1rem;
          padding:2px 5px;
          background: #e63a49;
          color: $white;
          border-radius: 5px;

        }
      }

    }
    .half{
      width: calc(50% - 10px);
    }
    .full{
      width: 100%;
    }

    .checker,.c-lead{
      font-size: 1.2rem;
    }
    .c-lead{
      width: 100%;
      margin-top: 40px;
    }
    dl{
      margin-bottom: 10px;
    }
    dt{
      padding: 10px;
      @include tablet{
        width: calc(100% - 100px);
      }
    }
    .c-btn_confirm{
      margin:40px auto 0 ;
      pointer-events: none;
      opacity: 0.6;
      &.is-active{
        pointer-events: inherit;
        opacity: 1;
      }
    }

    .checker{
      margin-top: 15px;
      position: relative;
      padding-left: 30px;
      .check{
        position: absolute;
        left: 5px;
        top:10px;
      }
    }
    .submit-btn-list{
      margin-top: 60px;
      justify-content: center;
      width: 100%;
      display: none;
      .c-btn_reset{
        margin-right: 30px;
      }


    }
    &.checked-confirm{
      .required{
        &.is-success{
          &:after{
            content: none;
          }
        }
        &.is-error{
          &:after{
            content: none;
          }
        }
      }
      input[type='text'],select,textarea{
        border: 1px solid $gray;
        border-radius: 10px;
        background: $white;
        pointer-events: none;
      }

      .select{
        pointer-events: none;
      }
      .checker,.c-lead,.c-btn_confirm{
        display: none;
      }
      .submit-btn-list{
        @include flex;
        @include tablet{
          display: block;
          .c-btn_reset,
          .c-btn_submit{
            margin: 0 auto;
            max-width: 250px;
          }
          .c-btn_submit{
            margin-top: 20px;
          }
        }

      }

    }

  }
}


